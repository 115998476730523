import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Brand"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Brand" />

      <PageNavigation>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Values</PageNavigationLink>
      </PageNavigation>

      <Section>
        <Paragraph>
          The brand colors exist to represent Hudl. Generally speaking, these
          colors are intended for marketing and other representations of the
          Hudl brand. Usage within the product itself should be carefully
          considered.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <Paragraph>
          Use of brand colors in the product itself should be minimal. When
          building UI elements, check{' '}
          <Link href="/guidelines/colors/content" isDesignCodeLink>
            content colors
          </Link>
          ,{' '}
          <Link href="/guidelines/colors/utility" isDesignCodeLink>
            utility colors
          </Link>{' '}
          and{' '}
          <Link href="/guidelines/colors/environments" isDesignCodeLink>
            environments
          </Link>{' '}
          instead.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use brand colors for interaction elements."
            img="colors-brand-dont"
          />
          <DontDoItem
            type="do"
            text="use them to communicate on behalf of Hudl."
            img="colors-brand-do"
          />
        </DontDo>
      </Section>

      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query brandColorsDesign {
    allColorsYaml(filter: { type: { eq: "Brand" } }) {
      edges {
        node {
          type
          colors {
            value
            common_name
          }
        }
      }
    }
  }
`;
